import { atom } from "recoil";

export const AtomSelectedTab = atom({
  key: "AtomSelectedTab",
  default: "market-movers"
}) 
export const AtomMostActiveTab = atom({
  key: "AtomMostActiveTab",
  default: ""
}) 

export const AtomActiveTableData = atom({
  key: "AtomActiveTableData",
  default: []
})

export const AtomActiveLoader = atom({
  key: "AtomActiveLoader",
  default: false
})