import axios from "axios";
import CryptoJS from "crypto-js";
import { getToken } from "../component/Common/CommonFunctions";
import { MarketbashUrl, MarketbashUrls, useEncrypt, VALUATION_URL, BASE_URL_V3, CP1_V2_URL, SERVICE_V3_URL, BLOG_POST_URL } from "../config/urls";
import JSON5 from 'json5'
const key = CryptoJS.enc.Utf8.parse("RfW3W2i%xi}Tat4qxjR!gmseq$B&E)H@");
const iv = CryptoJS.enc.Utf8.parse("Cx?Ol%ySeXjXdsMt");

const user = getToken();
const encryptHeaders = (value) => {
  if (useEncrypt && value) {
    return CryptoJS.AES.encrypt(value, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }
  return value;
};
const trimData = (values) => {
  if (typeof values !== "string" && !(values instanceof FormData))
    Object.keys(values).map((key) => {
      if (typeof values[key] === "string") values[key] = values[key].trim();
      return null;
    });
  return values;
};


const decryptedResponse = (decryptedData) => {
  if (useEncrypt) {
    const decrypted = CryptoJS.AES.decrypt(decryptedData, key, {
      iv,
    });
    let _replaceNAN = decrypted.toString(CryptoJS.enc.Utf8).replaceAll("NaN", null).replaceAll("-Infinity", null).replaceAll("Infinity", null)
    return JSON.parse(_replaceNAN);
  }
  return decryptedData;
};

const encryptedResponse = (data) => {
  if (useEncrypt) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(trimData(data)), key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted;
  }
  return data;
};

const getCommonHeaders = (url) => {
  if (getToken()) {
    return `Bearer ${getToken()}`;
  }

  return "";
};

export const Services = axios.create({
  baseURL: MarketbashUrl,
  // timeout: 1000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`,
    //'Authorization': 'token <your-token-here>'
  },
});

export const Service = axios.create({
  baseURL: MarketbashUrls,
  // timeout: 1000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${getToken()}`,
    //'Authorization': 'token <your-token-here>'
  },
});

// export const ServiceV2 = axios.create({
//   baseURL: BASE_URL_V2,
//   headers: {
//     Accept: "application/json",
//     Authorization: `Bearer ${getToken()}`
//   },
// });

export const ServiceV2 = {
  get: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "get",
      data: params,
      type: "ServiceV2",
    });
    return res.data;
  },
  post: (route, params) => new Promise(async (resolve, reject) => {
    try {
      const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
        route: route,
        method: "post",
        data: params,
        type: "ServiceV2",
      });
      return resolve(res.data);
    } catch (error) {
      console.log("=== error ", error)
      return reject(error);
    }

  }),
};
export const ValuationService = {
  get: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "get",
      data: params,
      type: "ValuationService",
    });
    return res.data;
  },
  post: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "post",
      data: params,
      type: "ValuationService",
    });
    return res.data;
  },
};
// export const ValuationService = axios.create({
//   baseURL: VALUATION_URL,
//   headers: {
//     Accept: "application/json",
//     Authorization: `Bearer ${getToken()}`
//   },
// });
export const ServiceV3 = {
  get: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "get",
      data: params,
      type: "ServiceV3",
    });
    return res.data;
  },
  post: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "post",
      data: params,
      type: "ServiceV3",
    });
    return res.data;
  },
};
export const BlogV3 = {
  get: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "get",
      data: params,
      type: "Blog",
    });
    return res.data;
  },
  post: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "post",
      data: params,
      type: "Blog",
    });
    return res.data;
  },
};
// export const ServiceV3 = axios.create({
//   baseURL: BASE_URL_V3,
//   headers: {
//     Accept: "application/json",
//     Authorization: `Bearer ${getToken()}`
//   },
// });

export const ServiceCP1V2 = axios.create({
  baseURL: " https://api.amassinginnovation.com",
  headers: {
    Accept: "application/json",
  },
});

ServiceCP1V2.interceptors.request.use(
  (config) => {
    const headers = {
      ...config.headers,
    };
    if (getToken()) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    config.headers = {
      ...headers,
    };
    if (useEncrypt) {
      if (config.data && !(config.data instanceof FormData)) {
        let data = encryptedResponse(config.data);
        config.data = { data };
      }
    }

    return config;
  },
  (error) => {
    console.log("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
ServiceCP1V2.interceptors.response.use(
  async (response) => {
    let apiResponse = { ...response };
    return {
      data: useEncrypt ? await decryptedResponse(apiResponse.data.data) : apiResponse.data,
      status: apiResponse.status,
    };
  },
  async (error) => {
    if (error?.response?.status === 401) {
      // window.location.href = '/login'
    }
    if (error.response.data.data && useEncrypt) {
      return Promise.reject({
        ...(await decryptedResponse(error.response.data.data)),
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export const ServiceCP1V1 = axios.create({
  baseURL: "https://api.amassinginnovation.com",
  headers: {
    Accept: "application/json",
  },
});

ServiceCP1V1.interceptors.request.use(
  (config) => {
    const headers = {
      ...config.headers,
    };
    if (getToken()) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    config.headers = {
      ...headers,
    };
    if (useEncrypt) {
      if (config.data && !(config.data instanceof FormData)) {
        let data = encryptedResponse(config.data);
        config.data = { data };
      }
    }

    return config;
  },
  (error) => {
    console.log("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
ServiceCP1V1.interceptors.response.use(
  async (response) => {
    let apiResponse = { ...response };
    return {
      data: useEncrypt ? await decryptedResponse(apiResponse.data.data) : apiResponse.data,
      status: apiResponse.status,
    };
  },
  async (error) => {
    if (error.response?.status === 401) {
      // window.location.href = '/login'
    }
    if (error.response.data.data && useEncrypt) {
      return Promise.reject({
        ...(await decryptedResponse(error.response.data.data)),
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const MarketServices = axios.create({
  baseURL: "",
  headers: {
    Accept: "application/json",
  },
})
MarketServices.interceptors.request.use(
  (config) => {
    const headers = {
      ...config.headers,
    };
    if (getToken()) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    config.headers = {
      ...headers,
    };
    if (useEncrypt) {
      console.log("config", config);
      // config.headers["osType"] = "web";
      // config.headers["isWeb"] = true;
      // config.headers["webversion"] = "0.0.570";
      // config.headers["version"] = process.env.REACT_APP_CUSTOMER_VERSION
      // config.headers["Pragma"] = "no-cache";

      if (config.data && !(config.data instanceof FormData)) {
        let data = encryptedResponse(config.data);
        config.data = { data };
      }
    }

    return config;
  },
  (error) => {
    console.log("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
MarketServices.interceptors.response.use(
  async (response) => {
    let apiResponse = { ...response };
    console.log("apiResponse MarketServices", apiResponse);
    return {
      data: useEncrypt ? await decryptedResponse(apiResponse.data.data) : apiResponse.data,
      status: apiResponse.status,
    };
  },
  async (error) => {
    if (error.response.status === 401) {
      // window.location.href = '/login'
    }
    if (error.response.data.data && useEncrypt) {
      console.log("apiResponse", {
        ...(await decryptedResponse(error.response.data.data)),
        status: error.response.status,
        statusText: error.response.statusText,
      })
      return Promise.reject({
        ...(await decryptedResponse(error.response.data.data)),
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const ServiceV4 = axios.create({
  baseURL: SERVICE_V3_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${getToken()}`,
  },
});
ServiceV4.interceptors.request.use(
  (config) => {
    const headers = {
      ...config.headers,
    };
    if (getToken()) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    config.headers = {
      ...headers,
    };
    if (useEncrypt) {
      console.log("config", config);
      // config.headers["osType"] = "web";
      // config.headers["isWeb"] = true;
      // config.headers["webversion"] = "0.0.570";
      // config.headers["version"] = process.env.REACT_APP_CUSTOMER_VERSION
      // config.headers["Pragma"] = "no-cache";

      if (config.data && !(config.data instanceof FormData)) {
        let data = encryptedResponse(config.data);
        config.data = { data };
      }
    }

    return config;
  },
  (error) => {
    console.log("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
ServiceV4.interceptors.response.use(
  async (response) => {
    let apiResponse = { ...response };
    return {
      data: useEncrypt ? await decryptedResponse(apiResponse.data.data) : apiResponse.data,
      status: apiResponse.status,
    };
  },
  async (error) => {
    if (error.response.status === 401) {
      // window.location.href = '/login'
    }
    if (error.response.data.data && useEncrypt) {
      return Promise.reject({
        ...(await decryptedResponse(error.response.data.data)),
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export const ServiceV5 = axios.create({
  baseURL: SERVICE_V3_URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${getToken()}`,
  },
});
ServiceV5.interceptors.request.use(
  (config) => {
    const headers = {
      ...config.headers,
    };
    if (getToken()) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    config.headers = {
      ...headers,
    };
    if (useEncrypt) {
      console.log("config", config);
      // config.headers["osType"] = "web";
      // config.headers["isWeb"] = true;
      // config.headers["webversion"] = "0.0.570";
      // config.headers["version"] = process.env.REACT_APP_CUSTOMER_VERSION
      // config.headers["Pragma"] = "no-cache";

      if (config.data && !(config.data instanceof FormData)) {
        let data = encryptedResponse(config.data);
        config.data = { data };
      }
      if (config.params && !(config.params instanceof FormData)) {
        let params = encryptedResponse(config.params);
        config.params = { params };
      }
    }

    return config;
  },
  (error) => {
    console.log("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
ServiceV5.interceptors.response.use(
  async (response) => {
    let apiResponse = { ...response };
    const respData = useEncrypt ? await decryptedResponse(apiResponse.data.data) : apiResponse.data
    console.log("respData ==", respData);
    return { data: respData }
  },
  async (error) => {
    console.log("error", error);
    if (error.response.status === 401) {
      // window.location.href = '/login'
    }
    if (error.response.data.data && useEncrypt) {
      return Promise.reject({
        ...(await decryptedResponse(error.response.data.data)),
        status: error.response.status,
        statusText: error.response.statusText,
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export const BlogPostData = {
  get: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "get",
      data: params,
      type: "BlogPostData",
    });
    return res.data;
  },
  post: async (route, params) => {
    const res = await ServiceCP1V2.post(getToken() ? `/fetch-server-data` : `/fetch-server-data-lock`, {
      route: route,
      method: "post",
      data: params,
      type: "BlogPostData",
    });
    return res.data;
  },
};
// export const BlogPostData = axios.create({
//   baseURL: BLOG_POST_URL,
//   headers: {
//     Accept: "application/json",
//     Authorization: `Bearer ${getToken()}`
//   },
// });

