import React, {useEffect} from 'react'
import FreePopup from '../Common/FreePopup/FreePopup'
import { getDecodeToken, getPrimeUser, getToken, getTrail } from '../Common/CommonFunctions';
import UpgradePopupPopup from '../Common/FreePopup/UpgradePopup';
import { useLocation, useNavigate } from 'react-router-dom';

const GlobalProvider = ({ children }) => {
  // Variables
  const userInfo = getDecodeToken();
  const isLogin = getToken();
  let isTrail = isLogin && !userInfo?.is_subscribed;
  const navigate = useNavigate();
  const location = useLocation().pathname;
  useEffect(() => {
    let routes = ["/register", "/login"]
let includes = routes.includes(location)
    if (!includes && !userInfo?.jti) navigate("/login")
  }, [userInfo,location])
  

  return (
    <>
      {isTrail ? <UpgradePopupPopup /> : <FreePopup />}
      <div>
        {children}
      </div>
    </>
  )
}

export default GlobalProvider